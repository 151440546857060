<template>
  <div class="screen">
    <h1>Admin</h1>
  </div>
</template>

<script>
export default {
  name: 'AdminView',
  async mounted () {
    let res = await this.userValid()
    if (res.code === 'ERR_BAD_REQUEST') {
      this.$router.push('/')
    }
  }
}
</script>

<style>

</style>