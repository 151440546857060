const state = {
  appId: null
}

const getters = {
  appId: state => state.appId
}

const mutations = {
  mutateAppId (state, id) {
    state.appId = id
  }
}

const actions = {
  setAppId ({ commit }, id) {
    commit('mutateAppId', id)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}