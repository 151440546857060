import { mapActions, mapGetters } from 'vuex'
import securitySvcs from '../services/security.svcs'

const app = {
  data () {
    return {
      imageRoot: process.env.VUE_APP_IMAGE_ROOT
    }
  },
  computed: {
    ...mapGetters({
      appId: 'appId'
    })
  },
  methods: {
    ...mapActions({
      setAppId: 'setAppId'
    }),
    async signIn (uObj) {
      let res = await securitySvcs.signinUser(uObj)
      if(res.data) {
        localStorage.setItem('axs', res.data)
        return true
      } else {
        return false
      }
    },
    async userValid () {
      return await securitySvcs.verifyUser()
    }
  }
}

export default app