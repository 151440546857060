import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/public/HomeView'
import SignupView from '@/public/SignupView'
import ConfirmView from '@/public/ConfirmView'
import AdminView from '@/private/AdminView'

const routes = [
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: ConfirmView
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
