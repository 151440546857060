<template>
  <div style="background-color: #262626; height: 100%; display: flex; flex-direction: column; align-content: center;">
    <div class="screen-container">
    
      <img :src="`${imageRoot}/lander_popv1.png`" 
        class="logo-main"/>
    
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView'
}
</script>
