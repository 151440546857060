<template>
  <div class="screen signup">
    <h1>Signup</h1>
    <p><input type="text" v-model="user.email" /></p>
    <p><input type="password" v-model="user.password" /></p>
    <button @click="createUser">SUBMIT</button>
  </div>
</template>

<script>
import securitySvcs from '@/libs/services/security.svcs'
export default {
  name: 'SignupView',
  data () {
    return {
      user: {
        email: 'msullivan1@austin.rr.com',
        username: 'msullivan1@austin.rr.com',
        password: 'Ou812@!tesT'
      }
    }
  },
  methods: {
    async createUser () {
      let res = await securitySvcs.registerUser(this.user)
      if(res.status === 200) {
        alert('the kick is good')
      }
    }
  }
}
</script>

<style>

</style>