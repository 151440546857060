import api from '@/libs/helpers/api'

class SecurityServices {
  
  async registerUser (uObj) {
    try {
      let res = await api.postAdmin('/sec/signup', uObj)
      return res
    } catch {
      console.log('failure')
    }
  }
  
  async confirmUser (uObj) {
    try {
      let res =  await api.postAdmin('/sec/confirm', uObj)
      return res
    } catch {
      console.log('failure')
    }
  }

  async resendConfirm (uObj) {
    try {
      let res =  await api.postAdmin('/sec/resend', uObj)
      return res
    } catch {
      console.log('failure')
    }
  }

  async signinUser (uObj) {
    try {
      let res =  await api.post('/sec/signin', uObj)
      if (res.status === 200) {
        return res
      } else {
        return false
      }
    } catch {
      console.log('failure')
    }
  }

  async verifyUser () {
    try {
      return await api.getAdmin('/verify')
    } catch {
      console.log('failure')
    }
  }

}

export default new SecurityServices()