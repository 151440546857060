<template>
  <div class="screen signup">
    <h1>Confirm View</h1>
    <p>{{ user }}</p>

    <input type="text" placeholder="USER NAME" v-model="confirmCode"/>


    <p>
      <button @click="confirm">Confirm</button>
    </p>
    <p>
      didn't get a code?<br/>
      <button @click="resend">Confirm</button>
      {{ confirmCode }}
    </p>
  </div>
</template>

<script>
import securitySvcs from '@/libs/services/security.svcs'
export default {
  name: 'ConfirmView',
  data () {
    return {
      user: null,
      confirmCode: null
    }
  },
  mounted () {
    try {
      this.user = this.$route.query.user
    } catch {
      this.user = null
    }
  },
  methods: {
    async confirm () {
      console.log({  
          username: this.user,
          confirmationCode: this.confirmCode
        })
      let res = await securitySvcs.confirmUser(
        {  
          username: this.user,
          confirmationCode: this.confirmCode
        }
      )
      console.log(res)
    },
    async resend () {
      let res = await securitySvcs.resendConfirm({username: this.user})
      console.log(res)
    }
  }
}
</script>

<style>

</style>