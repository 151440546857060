const state = {
  userId: null
}

const getters = {
  userId: state => state.userId
}

const mutations = {
  mutateuserId (state, id) {
    state.userId = id
  }
}

const actions = {
  setuserId ({ commit }, id) {
    commit('mutateuserId', id)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}